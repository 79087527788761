<template>
  <div>Page not found</div>
</template>

<script>
/**
 * HIS PAGE DOES NOT NEED TO BE POPULATED
 * As long as the route is defined, 404-requests are redirected to home page (because meta.auth is undefined)
 */
export default {}
</script>
